<template>
  <div>
    <el-card style="min-height: 45rem">
      <el-row :gutter="40">
        <el-col :span="12">
          <el-form>
            <el-form-item label="合伙人分佣总额">
              <el-input-number v-model="form.partner_award_total"></el-input-number>%
            </el-form-item>
            <el-divider></el-divider>
            <el-form-item label="乡镇代理区域奖励">
              <el-input-number v-model="form.street_area_award_fee"></el-input-number>元
            </el-form-item>
            <el-form-item label="乡镇代理自动升级条件">
              直推
              <el-input-number v-model="form.street_auto_up_invite_num"></el-input-number>
              单，且团队成员直推
              <el-input-number v-model="form.street_auto_up_team_invite_num"></el-input-number>
              单
            </el-form-item>
            <el-divider></el-divider>
            <el-form-item label="区县代理区域奖励">
              <el-input-number v-model="form.district_area_award_fee"></el-input-number>元
            </el-form-item>
            <el-form-item label="区县代理自动升级条件">
              直推
              <el-input-number v-model="form.district_auto_up_invite_num"></el-input-number>
              单，且团队成员直推
              <el-input-number v-model="form.district_auto_up_team_invite_num"></el-input-number>
              单
            </el-form-item>
            <el-divider></el-divider>
            <el-form-item label="市级代理区域奖励">
              <el-input-number v-model="form.city_area_award_fee"></el-input-number>元
            </el-form-item>
            <el-form-item label="市级代理自动升级条件">
              直推
              <el-input-number v-model="form.city_auto_up_invite_num"></el-input-number>
              单，且团队成员直推
              <el-input-number v-model="form.city_auto_up_team_invite_num"></el-input-number>
              单
            </el-form-item>
            <el-divider></el-divider>
            <el-form-item label="省级代理区域奖励">
              <el-input-number v-model="form.province_area_award_fee"></el-input-number>元
            </el-form-item>
            <el-form-item label="省级代理自动升级条件">
              直推
              <el-input-number v-model="form.province_auto_up_invite_num"></el-input-number>
              单，且团队成员直推
              <el-input-number v-model="form.province_auto_up_team_invite_num"></el-input-number>
              单
            </el-form-item>
            <el-form-item>
              <el-button @click="edit" type="primary">提交</el-button>
            </el-form-item>
          </el-form>
        </el-col>
        <el-col :span="12">
          <el-form>
            <el-form-item label="消费账户最高抵扣">
              <el-input-number v-model="form.cost_max_scale"></el-input-number>%
            </el-form-item>
            <el-form-item label="团队业绩奖励"></el-form-item>
            <el-table :data="team_sales">
              <el-table-column prop="id" width="80" label="编号"></el-table-column>
              <el-table-column prop="sales_price" width="180" label="业绩">
                <template #default="s">
                  <el-input-number size="mini" v-model="s.row.sales_price"></el-input-number>元
                </template>
              </el-table-column>
              <el-table-column prop="award" width="180" label="奖励">
                <template #default="s">
                  <el-input-number size="mini" v-model="s.row.award"></el-input-number>%
                </template>
              </el-table-column>
              <el-table-column label="操作">
                <template #header>
                  <el-button v-if="noCreate" @click="addTeamSales" type="primary">添加</el-button>
                </template>
                <template #default="s">
                  <el-button @click="editTeamSales(s.row)">{{s.row.id ? '修改' : '创建'}}</el-button>
                  <el-button type="danger" @click="delTeamSales(s.row,s.$index)">{{ s.row.id ? '移除' : '取消' }}</el-button>
                </template>
              </el-table-column>
            </el-table>
          </el-form>
        </el-col>
      </el-row>
    </el-card>
  </div>
</template>

<script>
export default {
  name: "set",
  data() {
    return {
      form:{
        partner_award_total:0,

        street_area_award_fee:0,
        street_auto_up_invite_num:0,
        street_auto_up_team_invite_num:0,

        district_area_award_fee:0,
        district_auto_up_invite_num:0,
        district_auto_up_team_invite_num:0,

        city_area_award_fee:0,
        city_auto_up_invite_num:0,
        city_auto_up_team_invite_num:0,

        province_area_award_fee:0,
        province_auto_up_invite_num:0,
        province_auto_up_team_invite_num:0,

        cost_max_scale:0,
      },
      team_sales:[],
    }
  },
  mounted() {
    this.load();
    this.loadTeamSales();
  },
  computed:{
    noCreate(){
      let out = this.team_sales.filter(e=>!e.id);
      return out.length === 0;
    },
  },
  methods: {
    loadTeamSales(){
      this.$api.addons.areaAgent.teamSalesRule().then(res=>{
        this.team_sales = res;
      })
    },
    editTeamSales(item){
      this.$api.addons.areaAgent.teamSalesRuleEdit(item).then(()=>{
        this.$message.success("操作成功");
        this.loadTeamSales();
      })
    },
    delTeamSales({id},index){
      if(!id){
        this.team_sales.splice(index,1);
        return
      }
      this.$api.addons.areaAgent.teamSalesRuleDel({id}).then(()=>{
        this.$message.success("操作成功");
        this.loadTeamSales();
      })
    },
    addTeamSales(){
      this.team_sales.unshift({
        sales_price:1000,
        award:1,
      })
    },
    load() {
      this.$api.addons.areaAgent.rule().then(res=>{
        this.form = res;
      })
    },
    edit(){
      this.$api.addons.areaAgent.ruleEdit(this.form).then(()=>{
        this.$message.success("操作成功");
        this.load();
      })
    },
  }
}
</script>

<style scoped>

</style>